import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isEdit: false,
	editedUserId: null,
	currentStep: 0,
	answers: {},
};

const formSlice = createSlice({
	name: 'progress',
	initialState,
	reducers: {
		stepIncrement: state => {
			state.currentStep = state.currentStep + 1;
		},
		stepDecrement: state => {
			const decrement = state.currentStep - 1;
			state.currentStep = Math.max(0, decrement);
		},
		setCurrentStep: (state, { payload }) => {
			state.currentStep = payload;
		},
		setProgress: (state, action) => {
			state.progress = action.payload;
		},
		setIsEdit: (state, { payload }) => {
			state.isEdit = payload;
		},
		updateProgress: (state, { payload }) => {},
		updateAnswers: (state, { payload }) => {
			const { _id, applicantTypesAvoidViewing, applicantTypes } = payload;
			const { active, addresses } = payload.ipRestrictions || {};
			let answers = { ...payload };
			if (_id) {
				state.editedUserId = _id;
			}
			if (applicantTypesAvoidViewing) {
				answers.applicants = applicantTypesAvoidViewing
					? applicantTypes.filter(applicant => !applicantTypesAvoidViewing.includes(applicant))
					: applicantTypes;
			}
			if (active) {
				answers.ipRestrictions = addresses;
				answers.ipRestrictionsActive = active;
			}
			state.answers = answers;
		},
		createUser: (state, { payload }) => {},
		resetForm: state => {
			state = initialState;
		},
	},
});

export const { reducer, actions } = formSlice;
