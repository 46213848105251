import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notification from '../../shared/components/ui/Notification/Notification';
import request from '../../utils/API/apiHandler';

const initialState = {
	createUserLoading: false,
	updateUserLoading: false,
	usersData: [],
	usersCount: 0,
	usersLoading: false,
	headerStats: [],
	headerStatsLoading: false,
	activeTab: 'all',
	showUserFormModal: false,
	showUserFormSidebar: false,
	showUserProfileSidebar: false,
	currentPage: 1,
	itemsPerPage: 10,
	userProfile: {},
	setProfileForUpdate: false,
	supervisors: [],
	deleteUserLoading: false,
	applicantTypes: [],
	changePasswordLoading: false,
	searchQuery: '',
	me: {},
	loadingMe: false,
	managementLinks: null,
};

export const getHeaderStats = createAsyncThunk('users/header-stats', async (payload, { getState }) => {
	const { user } = getState().auth;
	const result = await request({ url: '/users/headers' });
	return Promise.resolve({ headerTags: result, userRole: user.role });
});

export const getMe = createAsyncThunk('users/me', (payload, { getState }) => {
	const { user } = getState().auth;

	return request({ url: '/users/' + user._id, notify: true });
});

export const getApplicantTypes = createAsyncThunk('tickets/ats/main-flow-applicant-types', (payload, { getState }) => {
	return request({ url: `/tickets/ats/main-flow-applicant-types?avoidCondition=true` });
});

export const getUsers = createAsyncThunk('users/get', (payload, { getState }) => {
	const state = getState();
	const { activeTab, currentPage, itemsPerPage, searchQuery } = state.userManagement;
	const { user } = state.auth;
	let skip, limit;

	if (user.role === activeTab) {
		skip = currentPage === 1 ? 0 : (currentPage - 1) * itemsPerPage - 1;

		limit = currentPage === 1 ? itemsPerPage - 1 : itemsPerPage;
	} else {
		skip = (currentPage - 1) * itemsPerPage;

		limit = itemsPerPage;
	}

	const query = activeTab !== 'all' ? `?role=${activeTab}&skip=${skip}&limit=${limit}` : `?skip=${skip}&limit=${limit}`;

	return request({ url: '/users' + query + `&search=${searchQuery}`, notify: true });
});

export const createUser = createAsyncThunk('user/create', (payload, { getState }) => {
	const data = {};
	for (const key in payload) {
		if (payload[key] !== undefined) {
			data[key] = payload[key];
		}
	}
	return request({ url: '/users/create', method: 'post', data: JSON.stringify(data) });
});

export const getSupervisors = createAsyncThunk('user/get-supervisors', (payload, { getState }) => {
	const query = `?role=supervisor`;
	return request({ url: '/users' + query });
});

export const getManagementLinks = createAsyncThunk('user/get-management-links', (payload, { getState }) => {
	return request({ url: 'management/meta/links' });
});

export const deleteUser = createAsyncThunk('user/delete', (payload, { getState }) => {
	return request({ url: `/users/${payload}`, method: 'delete' });
});

export const updateUser = createAsyncThunk('user/update', (payload, { getState }) => {
	const { _id, updateData } = payload;
	const data = {};
	for (const key in updateData) {
		if (updateData[key] !== undefined) {
			data[key] = updateData[key];
		}
	}

	return request({ method: 'patch', url: `/users/${_id}`, data: JSON.stringify(data) });
});

export const changePassword = createAsyncThunk('user/password-change', (payload, { getState }) => {
	return request({
		method: 'post',
		url: `/users/password-change`,
		data: JSON.stringify(payload),
	});
});

const userMng = createSlice({
	name: 'userManagement',
	initialState,
	reducers: {
		removeSupervisor: (state, { payload }) => {
			state.supervisors = state.supervisors.filter(supervisor => supervisor._id !== payload);
		},
		setSearchQuery: (state, { payload }) => {
			state.searchQuery = payload;
			state.currentPage = 1;
		},
		setCurrentPage: (state, { payload }) => {
			state.currentPage = payload;
		},
		setItemsPerPage: (state, { payload }) => {
			state.itemsPerPage = payload;
		},
		switchTab: (state, { payload }) => {
			state.currentPage = 1;
			state.activeTab = payload;
		},
		toggleUserFormModal: state => {
			state.showUserFormModal = !state.showUserFormModal;
		},
		toggleUserFormSidebar: state => {
			state.showUserFormSidebar = !state.showUserFormSidebar;
		},
		toggleUserProfileSidebar: state => {
			state.showUserProfileSidebar = !state.showUserProfileSidebar;
		},
		setUserProfile: (state, { payload }) => {
			state.userProfile = payload;
			state.setProfileForUpdate = !state.setProfileForUpdate;
		},
		updateUserProfile: (state, { payload }) => {
			state.userProfile = { ...state.userProfile, ...payload };
		},
		updateUserGroupViewing: (state, { payload }) => {
			const user = state.usersData.find(user => user._id === payload);
			user.groupViewing = !user.groupViewing;
		},
		updateUserFileLocationViewing: (state, { payload }) => {
			const user = state.usersData.find(user => user._id === payload);
			user.fileLocationViewing = !user.fileLocationViewing;
		},
	},
	extraReducers: {
		[getHeaderStats.pending]: (state, action) => {
			state.headerStatsLoading = true;
		},
		[getHeaderStats.fulfilled]: (state, { payload }) => {
			const { headerTags, userRole } = payload;
			const stats = Object.entries(headerTags).map(el => {
				const role = el[0].slice(0, -1);
				const display = el[0][0].toUpperCase() + el[0].slice(1);
				return {
					role,
					display,
					count: el[1],
				};
			});

			if (['admin', 'developer'].includes(userRole)) {
				stats.push({
					role: 'all',
					display: 'All Users',
					count: stats.length ? stats.reduce((a, b) => a + b.count, 0) : 0,
				});
			}

			state.headerStats = stats;
			state.headerStatsLoading = false;
		},
		[getHeaderStats.rejected]: (state, { payload }) => {
			state.headerStatsLoading = false;
		},
		[getUsers.pending]: (state, { payload }) => {
			state.usersLoading = true;
		},
		[getUsers.rejected]: (state, { payload }) => {
			state.usersLoading = false;
		},
		[getUsers.fulfilled]: (state, { payload }) => {
			state.usersLoading = false;
			const usersData = payload.users.map(user => ({
				...user,
				fullName: `${user.firstName} ${user.lastName}`,
			}));

			if (state.currentPage === 1 && Object.keys(state.me).length) {
				state.usersData = [state.me, ...usersData];
			} else {
				state.usersData = usersData;
			}
			state.usersCount = payload.total;
		},
		[createUser.pending]: (state, { payload }) => {
			state.createUserLoading = true;
		},
		[createUser.rejected]: (state, { payload }) => {
			state.createUserLoading = false;
		},
		[createUser.fulfilled]: (state, { payload }) => {
			const { user } = payload;
			state.createUserLoading = false;
			notification('success', 'Succcess', 'User successfully created');
			state.showUserFormSidebar = false;
			if (user.role === state.activeTab || state.activeTab === 'all') {
				user.fullName = `${user.firstName} ${user.lastName}`;
				state.usersData = [user, ...state.usersData];
			}
		},
		[getSupervisors.pending]: (state, { payload }) => {
			state.supervisorsLoading = true;
		},
		[getSupervisors.rejected]: (state, { payload }) => {
			state.supervisorsLoading = false;
			state.showUserFormSidebar = false;
		},
		[getSupervisors.fulfilled]: (state, { payload }) => {
			state.supervisorsLoading = false;
			state.supervisors = payload.users;
		},
		[updateUser.pending]: (state, { payload }) => {
			state.updateUserLoading = true;
		},
		[updateUser.rejected]: (state, { payload }) => {
			state.updateUserLoading = false;
		},
		[updateUser.fulfilled]: (state, { payload }) => {
			state.updateUserLoading = false;
			state.showUserFormSidebar = false;
			notification('success', 'Succcess', 'User successfully updated');
		},
		[deleteUser.pending]: (state, { payload }) => {
			state.deleteUserLoading = true;
		},
		[deleteUser.rejected]: (state, { payload }) => {
			state.deleteUserLoading = false;
		},
		[deleteUser.fulfilled]: (state, { payload }) => {
			state.deleteUserLoading = false;
			// TODO: rename variable
			state.showUserFormSidebar = false;
			notification('success', 'Succcess', 'User successfully deleted');
		},
		[changePassword.pending]: (state, { payload }) => {
			state.changePasswordLoading = true;
		},
		[changePassword.rejected]: (state, { payload }) => {
			state.changePasswordLoading = false;
		},
		[changePassword.fulfilled]: (state, { payload }) => {
			state.changePasswordLoading = false;
			notification('success', 'Succcess', 'Password successfully changed');
		},
		[getMe.pending]: (state, { payload }) => {
			state.loadingMe = true;
		},
		[getMe.rejected]: (state, { payload }) => {
			state.loadingMe = false;
		},
		[getMe.fulfilled]: (state, { payload }) => {
			state.loadingMe = false;
			state.me = {
				...payload,
				fullName: `${payload?.firstName} ${payload?.lastName}`,
			};

			if (state.currentPage === 1) {
				if (state.usersData.length) {
					state.usersData = [state.me, ...state.usersData.slice(1)];
				} else {
					state.usersData = [state.me, ...state.usersData];
				}
			}
		},
		[getApplicantTypes.pending]: (state, { payload }) => {
			state.loadingMe = true;
		},
		[getApplicantTypes.rejected]: (state, { payload }) => {
			state.loadingMe = false;
		},
		[getApplicantTypes.fulfilled]: (state, { payload }) => {
			state.loadingMe = false;
			state.applicantTypes = payload?.applicantTypes?.map(type => type?.name) || [];
		},
		[getManagementLinks.pending]: (state, { payload }) => {
			state.linksLoading = true;
		},
		[getManagementLinks.rejected]: (state, { payload }) => {
			state.linksLoading = false;
		},
		[getManagementLinks.fulfilled]: (state, { payload }) => {
			state.linksLoading = false;
			state.managementLinks = payload;
		},
	},
});

export const { reducer, actions } = userMng;
